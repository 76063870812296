@use 'styles/functions' as fn;
@use 'styles/variables' as var;

.tcb {
    position: fixed;
    z-index: 99;
    background: #0000004D;
    width: 100%;
    height: 100%;
}

.tcb-card {
    padding: fn.spacing(3);
    max-width: 1440px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - fn.spacing(3.5));
    border-radius: fn.spacing(1);
    background-color: var.$color-neutral-0;
    position: fixed;
    bottom: fn.spacing(4);
    display: flex;
    z-index: 10;
}

.tcb-x {
    cursor: pointer;
    position: absolute;
    top: fn.spacing(1.5);
    right: fn.spacing(1.5);
}

.tcb-content {
    padding: fn.spacing(1) fn.spacing(5.5) fn.spacing(1) fn.spacing(2);
    text-align: justify;
}

.tcb-btn {
    float: right;
    
    /* shape-outside only apply to float elements */
    height: 100%;

    /* take all the height */
    margin-left: fn.spacing(2);
    margin-right: fn.spacing(-4);

    /* push the image to the bottom */
    display: flex;
    align-items: flex-end;
    shape-outside: inset(calc(100% - 36px) 0 0);
}

.button-padding {
    padding-right: fn.spacing(3);
    padding-left: fn.spacing(3);
}

@media screen and (min-width: var.$breakpoint-lg) {
    .tcb-btn {
        margin-top: fn.spacing(1.5);
    }
}